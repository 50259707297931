@import "../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";

$name: '.Carousel';

#{$name} {


    &-title {
        font-size: 1.8rem;
        margin: 0 0 .5rem;
        line-height: 1;

        @include media-breakpoint-up(md) {
            font-size: 2rem;
        }

        @include media-breakpoint-up(lg) {
            font-size: 3rem;
            margin: 0 0 1rem;
        }

        @include media-breakpoint-up(xl) {
            font-size: 4rem;
        }
    }

    &-intro {
        font-size: .875rem;
        margin-bottom: .5rem;

        @include media-breakpoint-up(md) {
            font-size: 1rem;
        }

        @include media-breakpoint-up(lg) {
            font-size: 1.25rem;
            margin-bottom: 1rem;
        }
    }

    &-button {
        display: inline-block;
        margin-top: 1rem;

        @include media-breakpoint-up(lg) {
            padding: 1rem 3rem;
            font-size: 1rem;
        }
    }

    &-textWrapper {
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
        right: auto;
        width: 90%;
        padding-top: 0;

        @include media-breakpoint-up(md) {
            width: 70%;
        }

        @include media-breakpoint-up(lg) {
            width: 60%;
        }

        @include media-breakpoint-up(xl) {
            width: 50%;
        }
    }

    &-slide {
        min-height: 15rem;
        overflow: hidden;

        @include media-breakpoint-up(md) {
            min-height: 25rem;
        }

        @include media-breakpoint-up(lg) {
            min-height: 30rem;
        }

        @include media-breakpoint-up(xl) {
            min-height: 37rem;
        }
    }

    &-image {
        position: absolute;
        min-height: 100%;
        min-width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &-placeholderTop {
        position: relative;

        @include media-breakpoint-up(lg) {
            position: absolute;
            left: 0;
            right: 0;
        }
    }

    &-placeholderBottom {
        position: relative;
        margin-top: -20px;
        border-radius: 3px;
        background-color: #fff;
        box-shadow: 0 -10px 12px rgba(0,0,0,.15);

        @include media-breakpoint-up(lg) {
            margin-top: -60px;
        }

        @include media-breakpoint-down(lg) {
            width: auto;
            margin-left: 1rem;
            margin-right: 1rem;
        }
    }

    &-indicatorList {
        @include media-breakpoint-down(sm) {
            top: 12rem;
            margin: 0;
        }

        @include media-breakpoint-up(md) {
            top: 22rem;
        }

        @include media-breakpoint-up(lg) {
            top: 24rem;
        }

        @include media-breakpoint-up(xl) {
            top: 31rem;
        }
    }
}
